<template>
  <el-dialog
    center
    @close="closeModal()"
    title="Google Authenticator"
    :visible="visible">
      <div v-if="google2fa.data" id="print">
          <div class="col-md-12">
              <div class="panel panel-default">
                  <div class="panel-body" style="text-align: center;">
                      <p>Set up two factor authentication by scanning the barcode below.</p>
                      <div class="row">
                          <div class="col-md-6">
                              <div v-html="google2fa.data.QR_google2fa_Image" />
                          </div>
                          <div class="col-md-1">
                              <div class="">
                                  <div class="line"></div>
                                  <div class="wordwrapper">
                                      <div class="word">or</div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-md-5 google_secret">
                              You can use the code <br>{{ google2fa.data.google2fa_secret }}
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <span slot="footer" class="dialog-footer">
              <button class="btn btn-danger" @click="closeModal()">Cancel</button>&nbsp;
          <button class="btn btn-info" @click="print()"><i class="fa fa-print"></i>Print </button>
            </span>
  </el-dialog>
</template>

<script>
import {Dialog} from 'element-ui'

export default {
  name: 'google2fa-modal',

  components: {
    [Dialog.name]: Dialog,
  },

  props: {
    visible: {
      type: Boolean,
      default: 0
    },
    google2fa: {},
  },
    mounted(){
    },
  methods: {

    closeModal() {
      this.$emit('close');
    },

    confirmModal() {
      this.$emit('confirm');
    },
      print(){
          const prtHtml = document.getElementById('print').innerHTML;
          let stylesHtml = '';
          for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
              stylesHtml += node.outerHTML;
          }
          var mywindow = window.open('', 'PRINT');

          mywindow.document.write(`<!DOCTYPE html>
                                    <html>
                                      <head>
                                        ${stylesHtml}
                                      </head>
                                      <body>
                                        ${prtHtml}
                                      </body>
                                    </html>`);

          mywindow.document.close();
          mywindow.focus();

          mywindow.onload = function () {
              mywindow.print();
          }

          return true;
      }

  }

}
</script>

<style>
    .line {
        position: absolute;
        left: 49%;
        top: 0;
        bottom: 0;
        width: 1px;
        background: #ccc;
        z-index: 1;
    }

    .wordwrapper {
        text-align: center;
        height: 12px;
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        margin-top: -12px;
        z-index: 2;
    }

    .word {
        color: #ccc;
        text-transform: uppercase;
        letter-spacing: 1px;
        padding: 3px;
        font: bold 12px arial,sans-serif;
        background: #fff;
    }
    .google_secret{
        align-items: center;
        justify-content: center;
        display: flex;
    }
    .el-dialog--center .el-dialog__footer {
        text-align: right;
    }
</style>
