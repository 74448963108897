<template>

  <div class="row" ref="userForm">

    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-xs-6 col-md-6 col-sm-12">

                <ValidationProvider
                  vid="first_name"
                  rules="required"
                  name="First Name"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="First Name *"
                            name="first_name"
                            fou
                            v-model="formData.first_name">
                  </fg-input>
                </ValidationProvider>

                <ValidationProvider
                  vid="last_name"
                  rules="required"
                  name="Last Name"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="Last Name *"
                            name="last_name"
                            fou
                            v-model="formData.last_name">
                  </fg-input>
                </ValidationProvider>

                <ValidationProvider
                  vid="email"
                  rules="required|email"
                  name="Email"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="email"
                            :error="failed ? errors[0]: null"
                            label="Email *"
                            name="email"
                            fou
                            v-model="formData.email">
                  </fg-input>
                </ValidationProvider>


                <fg-input type="mobile"
                          label="Mobile"
                          name="mobile"
                          fou
                          v-model="formData.mobile">
                </fg-input>

                <ValidationProvider
                  vid="role_id"
                  rules="required"
                  name="The Role"
                  v-slot="{ passed, failed,errors }">
                  <fg-select
                    name="role_id"
                    size="large"
                    filterable
                    placeholder="Select Role"
                    :input-classes="'select-default'"
                    :label="'Roles *'"
                    :error="failed ? errors[0]: null"
                    :list="roles"
                    :listItemLabel="'name'"
                    :listItemValue="'id'"
                    v-model="formData.role_id">
                  </fg-select>
                </ValidationProvider>

                <ValidationProvider
                  v-if="!editMode"
                  vid="password"
                  :rules="passVal"
                  name="The Password"
                  v-slot="{ passed, failed,errors }"
                >
                  <fg-input type="password"
                            :error="failed ? errors[0]: null"
                            :hasSuccess="passed"
                            label="Password"
                            v-model="formData.password">
                  </fg-input>
                </ValidationProvider>

                <div class="form-group">
                  <label>Is Active</label>&nbsp;
                  <l-switch v-model="formData.is_active">
                    <i class="fa fa-check" slot="on"></i>
                    <i class="fa fa-times" slot="off"></i>
                  </l-switch>
                </div>

              </div>
              <div class="col-xs-6 col-md-6 col-sm-12">
                <single-file-uploader-file-manager
                  :preview-width="'200px'"
                  :preview-height="'200px'"
                  v-model="formData.profile_image"
                  :accepted-files="imagesExtensions">
                </single-file-uploader-file-manager>


              </div>
            </div>

          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>
            <l-button @click="$router.push('/users/list')" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>


      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {required, confirmed, min, regex} from "vee-validate/dist/rules";

import {Switch as LSwitch} from '@/components'
import {ImageUploader} from '@/components'
import {PrimeUploader} from '@/components'
import {FormGroupSelect} from '@/components'
import FgSelect from "@/components/Inputs/formGroupSelect";
import SingleFileUploaderFileManager from "@/components/FileManager/SingleFileUploaderFileManager";


extend("required", {
  message: "{_field_} is required"
});
extend("confirmed", confirmed)
extend("required", required);
extend("min",{...min,message:'the password must be 8 character or more'});
extend("regex",{...regex,message:'Password requires 1 of each of the following: uppercase letter, lowercase letter, number.'});


export default {

  components: {
    PrimeUploader,
    FgSelect,
    LSwitch,
    FormGroupSelect,
    ImageUploader,
    SingleFileUploaderFileManager
  },

  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,
      passVal:{required: true,min: 8,regex: '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).*$' },
      imagesExtensions:this.getImagesExtensions(),
      formTitle: "",
      entityNotFoundError: false,
      submitting: false,

      formData: {
        first_name: "",
        last_name: "",
        email: "",
        mobile: "",
        profile_image: "",
        is_active: true,
        role_id: null,
        password: "",
      },

      roles: []

    };
  },

  mounted() {

    this.loader = this.$loading.show({
      container: this.$refs.userForm
    });

    this.id = this.$route.params['id'];
    if (this.id !== undefined) {
      this.editMode = true;
      this.formTitle = "Edit User";
    } else {
      this.editMode = false;
      this.formTitle = "Add User";
    }

    this.axios.post("users/builder").then((response) => {
      this.roles = response.data.roles;
      if (this.editMode) {
        this.getUser();
      } else {
        this.loader.hide();
      }
    }).catch((error) => {
      console.error(error);
    })
  },


  methods: {
    getUser() {
      this.axios.get("users/get/" + this.id).then((response) => {
        this.formData = response.data;
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "User Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();
        } else {
          console.error(error);
        }
      })
    },
    handleImage(e) {
      this.formData.profile_image = e;
    },
    submit() {
      let request;
      let successMessage;
      this.submitting = true;

      if (this.editMode === true) {
        let updateData = {...this.formData};
        this.$delete(updateData, 'password');
        request = this.axios.put("users/update/" + this.id, updateData);
        successMessage = "User Updated Successfully";
      } else {
        request = this.axios.post("users/create", this.formData);
        successMessage = "User Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/users/list");
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      }).finally(() => {
        this.submitting = false;
      });
    }

  }
}
</script>

<style>
</style>
