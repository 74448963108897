<template>
  <div class="row" ref="userList">

    <div class="col-12">

      <card>
        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">Users List</h4>
          <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['users/create'])"
                       :to="'/users/create'"
                       class="btn btn-info btn-wd">
            Add New
            <span class="btn-label">
                    <i class="fa fa-plus"></i>
                </span>
          </router-link>
        </div>

        <div>
          <general-data-table
            ref="table"
            :api-url="'users/index'">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :align="column.align"
                             :sortable="column.sortable"
                             :prop="column.value"
                             :label="column.label">
            </el-table-column>

            <el-table-column
              :min-width="120"
              :fixed="fixedColumn"
              align="center"
              label="Active">
              <template slot-scope="props">

                <l-switch v-if="$store.getters['auth/haveOneOfPermissions'](['users/change-active-status'])"
                          v-model="props.row.is_active" @input="handleActiveStatus(props.row)">
                  <i class="fa fa-check" slot="on"></i>
                  <i class="fa fa-times" slot="off"></i>
                </l-switch>

              </template>
            </el-table-column>

            <el-table-column
              :min-width="120"
              :fixed="fixedColumn"
              align="center"
              label="Actions">
              <template slot-scope="scope">

                <router-link v-if="$store.getters['auth/checkAccess']('users/change-password')"
                             v-tooltip.top-center="'Change Password'" class="btn-info btn-simple btn-link"
                             :to="'/users/changePassword/'+scope.row.id">
                  <i class="fa fa-key"></i></router-link>

                <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['users/update'])"
                             v-tooltip.top-center="'Edit'"
                             class="btn-warning btn-simple btn-link"
                             :to="'/users/edit/'+scope.row.id">
                  <i class="fa fa-edit"></i></router-link>

                <router-link  v-tooltip.top-center="'Log'" v-if="$store.getters['auth/haveOneOfPermissions'](['users_logs_view'])"
                    class="btn-info btn-simple btn-link"
                    :to="'/logs/users_'+scope.row.id"><i class="fa fa-eye"></i></router-link>

                <router-link  v-tooltip.top-center="'User Actions'" v-if="$store.getters['auth/haveOneOfPermissions'](['logs/user-actions'])"
                              class="btn-info btn-simple btn-link"
                              :to="'/logs/user-actions/'+scope.row.id"><i class="fa fa-address-card"></i></router-link>

                <a v-if="$store.getters['auth/haveOneOfPermissions'](['users/delete'])"
                   v-tooltip.top-center="'Delete'"
                   class="btn-danger btn-simple btn-link"
                   @click="openDeleteModal(scope.row.id)"><i class="fa fa-times"></i></a>
                <a v-if="$store.getters['auth/haveOneOfPermissions'](['users/google2fa'])"
                   v-tooltip.top-center="'Google 2 Factor'"
                   class="btn-primary btn-simple btn-link"
                   @click="openGoogle2faModal(scope.row.id)"><i class="fab fa-google"></i></a>
              </template>
            </el-table-column>

          </general-data-table>
        </div>

      </card>


      <delete-modal :visible="deleteModalVisibility"
                    :message="'Are you sure you want to delete this user?'"
                    @close="closeDeleteModal()"
                    @confirm="confirmDeleteModal()">
      </delete-modal>

      <google2fa-modal :visible="google2faModalVisibility"
                       :google2fa="google2fa"
                       :key="googleModalKey"
                       @close="closeGoogle2faModal()"
                       >

      </google2fa-modal>

    </div>

  </div>
</template>
<script>
import {TableColumn} from 'element-ui'
import {NewPagination as NLPagination} from '@/components'
import {DeleteModal as DeleteModal} from '@/components'
import {Switch as LSwitch} from '@/components'
import GeneralDataTable from "@/components/GeneralDataTable";
import Google2faModal from "../../components/Google2FaModal";

export default {
  components: {
    Google2faModal,
    NLPagination,
    DeleteModal,
    [TableColumn.name]: TableColumn,
    LSwitch,
    GeneralDataTable
  },

  data() {

    return {
      tableColumns: [],
      fixedColumn: null,
      deleteModalVisibility: false,
      toDeleteId: undefined,
      google2faModalVisibility: false,
      google2fa: {},
      googleModalKey: 0,
      loader: {},
    }

  },
  created(){
        this.responsiveViewPort();
  },
  methods: {
    handleActiveStatus(row) {
      let data = {
        'is_active': row.is_active
      }
      this.axios.post("users/change-active-status/" + row.id, data).then((response) => {
        this.$notify({
          message: "User updated successfully",
          timeout: 1000,
          type: 'success'
        });
      }).catch((error) => {
        console.log(error)
      })
    },

    openDeleteModal(id) {
      this.deleteModalVisibility = true;
      this.toDeleteId =id;
    },

    closeDeleteModal() {
      this.deleteModalVisibility = false;
    },

    confirmDeleteModal() {

      let data = {
          'id': this.toDeleteId,
      }
      this.axios.delete("users/delete", {headers: {}, data}).then((response) => {
        this.$notify({
          message: "User deleted successfully",
          timeout: 1000,
          type: 'success'
        });

        this.$refs.table.getData("updateData");

      }).catch((error) => {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      })

      this.closeDeleteModal();
      this.toDeleteId = undefined;
    },
    responsiveViewPort(){
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
            this.fixedColumn = null;
            this.tableColumns = [
                {label: 'Full Name', value: 'full_name', minWidth: '200', align: 'center'},
                {label: 'Role', value: 'role.name', minWidth: '200', align: 'center'},
            ];
        }else{
            this.fixedColumn = 'right';
            this.tableColumns = [
                {label: 'Full Name', value: 'full_name', minWidth: '200', align: 'center'},
                {label: 'Email', value: 'email', minWidth: '200', align: 'center'},
                {label: 'Mobile', value: 'mobile', minWidth: '200', align: 'center'},
                {label: 'Role', value: 'role.name', minWidth: '200', align: 'center'},
            ];
        }
    },
    openGoogle2faModal(id) {
        this.loader = this.$loading.show({
            container: this.$refs.pollList
        });
        let data = {
            id: id,
        };
        this.axios.post("users/getGoogle2FaInfo", data).then((response) => {
            this.google2fa = response;
            this.googleModalKey++;
            this.google2faModalVisibility = true;
            this.loader.hide();
        }).catch((error) => {
            console.error(error);
        });

    },
    closeGoogle2faModal(){
        this.google2faModalVisibility = false;
    }
  },

}
</script>
<style>
</style>
